import { makeStyles, createStyles } from '@material-ui/core/styles';

import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';

import Typography from '@material-ui/core/Typography';

import Button from '@material-ui/core/Button';

import { Fade, AttentionSeeker } from 'react-awesome-reveal';

import useVisibilityAwareness from '../../hooks/useVisibilityAwareness';

const useStyles = makeStyles( ( theme ) =>
  createStyles( {
    rootBox: {
      //backgroundColor: '#fff',
      display: 'flex',
      paddingBottom: 10,
      color: '#fff'
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center'
    },
    vMiddle: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center'
    },
    row: {
      flexGrow: 1
    },
    btn: {
      color: '#fff',
      //borderColor: '#fff'
    }
  } ),
);

function Footer ( { slug, activate } ) {

  const classes = useStyles();

  const ref = useVisibilityAwareness( slug, activate );

  const email = () => {
    window.location.href = 'mailto:info@areascan.ai?subject=Emailing+using+the+website+button';
  };

  return (

    <Box ref={ref} id={slug} className={classes.rootBox} minHeight="100vh">

      <Container className={classes.container}>

        <Grid container className={classes.row} spacing={4}>

          <Grid item sm={12} md={6} lg={6} className={classes.vMiddle}>
            <Box textAlign="left">
              <Fade direction="left" delay={500} triggerOnce>
                <Typography variant="h3" gutterBottom>
                  Get in Touch
                </Typography>
              </Fade>
              <Fade delay={1000} triggerOnce>
                <Typography variant="body2" gutterBottom>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean iaculis neque metus, in bibendum quam mollis et. Morbi efficitur tincidunt tellus. Fusce non malesuada magna.
                </Typography>
              </Fade>
              <br />
              <AttentionSeeker effect="rubberBand">
                <Button className={classes.btn} color="primary" variant="outlined" onClick={email}>Email Us</Button>
              </AttentionSeeker>
            </Box>
          </Grid>

          <Grid item sm={12} md={6} lg={6} className={classes.vMiddle}>
            <Box textAlign="left">
              <Fade direction="left" delay={500} triggerOnce>
                <Typography variant="h4" gutterBottom>
                  Address:
                </Typography>
              </Fade>
              <Fade delay={1000} triggerOnce>
                <Typography variant="h6" gutterBottom>
                  Street Name
                </Typography>
                <Typography variant="h6" gutterBottom>
                  Additional Address Info
                </Typography>
                <Typography variant="h6" gutterBottom>
                  Miami, FL 33133
                </Typography>
              </Fade>
            </Box>
          </Grid>
          
        </Grid>

        <Grid container className={classes.row} spacing={4}>
          <Grid item sm={12} md={12} lg={12} className={classes.vMiddle}>
            <Box textAlign="center">
                <Typography variant="caption">
                  &copy; Copyright 2020 ChangeTracker
                </Typography>
            </Box>
          </Grid>
        </Grid>

      </Container>

    </Box>

  );
}

export default Footer;
