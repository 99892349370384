import React, { useState, useCallback } from 'react';

import { makeStyles, createStyles } from '@material-ui/core/styles';

import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';

import IconButton from '@material-ui/core/IconButton';
import OndemandVideoOutlined from '@material-ui/icons/OndemandVideoOutlined';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';

import Typography from '@material-ui/core/Typography';

import { primary } from '../../style/colors';

import logo from '../../assets/logo.svg';

import { Zoom, AttentionSeeker } from 'react-awesome-reveal';

import useVisibilityAwareness from '../../hooks/useVisibilityAwareness';

const useStyles = makeStyles( ( theme ) =>
  createStyles( {
    rootBox: {
      backgroundColor: primary,
      display: 'flex'
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
      //alignItems: 'center',
      justifyContent: 'center'
    },
    vMiddle: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center'
    },
    row: {
      flexGrow: 1
    },
    vSpace: {
      height: 100
    },
    videoButton: {
      color: '#fff'
    },
    videoIcon: {
      color: '#fff',
      fontSize: 50
    }
  } ),
);

function Intro ( { slug, activate } ) {

  const classes = useStyles();

  const [vidVisible, setVidVisible] = useState( false );

  const toggleVideo = useCallback( () => {
    setVidVisible( !vidVisible );
  }, [vidVisible] );

  const ref = useVisibilityAwareness( slug, activate );

  return (

    <Box ref={ref} id={slug} className={classes.rootBox} minHeight="100vh">

      <Container className={classes.container}>

        <Grid container className={classes.row} spacing={0} style={{alignSelf: 'flex-start'}}>
          <Grid item xs={3} sm={3} md={3} lg={2} xl={1}>
            <img src={logo} alt="ChangeTracker Logo" />
          </Grid>
        </Grid>

        <Grid container className={classes.row} spacing={2} style={{flex:10}}>
          <Grid item sm={12} md={6} lg={6} className={classes.vMiddle}>
            <Box color="white" textAlign="left">
              <Zoom triggerOnce>
                <div>
                  <Typography variant="h2">
                    ChangeTracker AI
                  </Typography>
                  <Typography variant="h3" gutterBottom>
                    Cybernetically Enhanced<br /> Change Management
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean iaculis neque metus, in bibendum quam mollis et. Morbi efficitur tincidunt tellus. Fusce non malesuada magna. Aliquam sem libero, convallis non mi a, aliquam interdum massa. Quisque pulvinar mi ut elit vehicula, non lacinia arcu euismod. Aliquam sodales nibh eleifend, posuere quam id, dignissim leo. Vivamus eu velit non lorem semper lobortis sit amet quis sapien.
                  </Typography>
                </div>
              </Zoom>
            </Box>
          </Grid>
          <Grid item sm={12} md={6} lg={6} className={classes.vMiddle}>
            <Box color="white" textAlign="center">
              <Typography variant="body1" gutterBottom>
                Play the Video
              </Typography>
              <AttentionSeeker effect="rubberBand">
                <IconButton className={classes.videoButton} onClick={toggleVideo}>
                  <OndemandVideoOutlined className={classes.videoIcon} />
                </IconButton>
              </AttentionSeeker>
              <Dialog onClose={toggleVideo} aria-labelledby="simple-dialog-title" open={vidVisible}>
                <DialogTitle>ChangeTracker Introduction</DialogTitle>
                <iframe title="ChangeTracker Introduction" width="560" height="315" src="https://www.youtube.com/embed/ghyUIOibp3A" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </Dialog>
            </Box>
          </Grid>
        </Grid>

      </Container>

    </Box>

  );
}

export default Intro;
