import React, { useState, useCallback } from 'react';

import { makeStyles, createStyles } from '@material-ui/core/styles';

import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';

import Typography from '@material-ui/core/Typography';

import { primary, secondary } from '../style/colors';

import logo from '../assets/logo.svg';

import CodeInput from '../components/CodeInput';

import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';


import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}



const useStyles = makeStyles( ( theme ) =>
  createStyles( {
    rootBox: {
      backgroundColor: primary,
      display: 'flex'
    },
    row: {
      flexGrow: 1
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: secondary,
    },
    logo: {
      width: 300
    }
  } ),
);

function remoteAuth ( code ) {

  return fetch( 'https://dev-api.areascan.io/v1/admin/tokens/current/validate', {
    method: 'POST',
    headers: {
      'Authorization': 'Bearer ' + code
    }
  } )//.then( response => response.json() )
  //.then( data => console.log(data) );

}

function Auth ( { onSuccess } ) {

  const classes = useStyles();

  const [progress, setProgress] = useState( false );

  const [error, setError] = useState( false );

  const handleErrorClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setError( false );
  };

  const onCodeReceived = useCallback( ( code ) => {
    
    setProgress( true );

    remoteAuth( code ).then( ( response ) => {
      //console.log( response.json() );
      if ( response.status === 200 ) {
        onSuccess();
      } else {
        setError( true );
      }
      setProgress( false );
    } ).catch( ( error ) => {
      console.log( 'error', error );
      setProgress( false );
    } );

  }, [ onSuccess ] );

  return (

    <Box className={classes.rootBox} minHeight="100vh" alignItems="center">

      <Container>

        <Grid container className={classes.row} spacing={2} justify="center">
          <img className={classes.logo} src={logo} alt="ChangeTracker Logo" />
        </Grid>

        <Grid container className={classes.row} spacing={2} justify="center">
          <Box color="white" textAlign="center">
            <Typography variant="h1" gutterBottom>
            ChangeTracker
            </Typography>
            <Typography variant="body1" gutterBottom>
              Type your Access Code
              <br /><br />
            </Typography>
          </Box>
        </Grid>

        <CodeInput
          autoFocus
          length={6}
          disabled={progress}
          onChangeValue={onCodeReceived}
        />

        <Backdrop className={classes.backdrop} open={progress}>
          <CircularProgress color="inherit" />
        </Backdrop>

        <Snackbar open={error}
        autoHideDuration={6000}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        onClose={handleErrorClose}>
          <Alert onClose={handleErrorClose} severity="error">
            Authorization Error!
          </Alert>
        </Snackbar>

      </Container>

    </Box>

  );
}

export default Auth;

// https://medium.com/@fast.nguyen/build-otp-input-with-reactjs-hooks-5699eb58b427 used
// https://github.com/dannyradden/single-character-input-boxes#readme
