import { makeStyles, createStyles } from '@material-ui/core/styles';

import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';

import Typography from '@material-ui/core/Typography';

import { Fade, Bounce } from 'react-awesome-reveal';

import Paper from '@material-ui/core/Paper';

import useVisibilityAwareness from '../../hooks/useVisibilityAwareness';


const useStyles = makeStyles( ( theme ) =>
  createStyles( {
    rootBox: {
      backgroundColor: '#fff',
      display: 'flex',
      paddingBottom: 10
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
      //alignItems: 'center',
      justifyContent: 'center'
    },
    vMiddle: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center'
    },
    row: {
      flexGrow: 1
    },
    vSpace: {
      height: 100
    },
    videoButton: {
      color: '#fff'
    },
    videoIcon: {
      color: '#fff',
      fontSize: 50
    }
  } ),
);

function WhyUs ( { slug, activate } ) {

  const classes = useStyles();

  const ref = useVisibilityAwareness( slug, activate );

  return (

    <Box ref={ref} id={slug} className={classes.rootBox} minHeight="100vh">

      <Container className={classes.container}>

        <Grid container className={classes.row} spacing={2} style={{flex:10}}>
          <Grid item sm={12} md={6} lg={6} className={classes.vMiddle}>
            <Box textAlign="left">
              <Fade direction="left" delay={500} triggerOnce>
                <Typography variant="h2" gutterBottom>
                  Why ChangeTracker?
                </Typography>
              </Fade>
              <Fade delay={1000} triggerOnce>
                <Typography variant="body2" gutterBottom>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean iaculis neque metus, in bibendum quam mollis et. Morbi efficitur tincidunt tellus. Fusce non malesuada magna. Aliquam sem libero, convallis non mi a, aliquam interdum massa. Quisque pulvinar mi ut elit vehicula, non lacinia arcu euismod. Aliquam sodales nibh eleifend, posuere quam id, dignissim leo. Vivamus eu velit non lorem semper lobortis sit amet quis sapien. Duis eget velit nec est lobortis elementum. Fusce lorem neque, congue in leo at, fringilla hendrerit eros. Phasellus vehicula lorem nec purus mattis, eget elementum tortor pellentesque.
                </Typography>
              </Fade>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} className={classes.vMiddle}>
            <Box textAlign="center">
              
              <Grid container className={classes.row} spacing={2}>

                <Grid item xs={6} sm={6} md={6} lg={6}>
                  <Bounce delay={1500} triggerOnce>
                    <Paper elevation={3}>
                      <img alt="Placeholder" src="https://via.placeholder.com/100x200.png?text=Placeholder" />
                    </Paper>
                  </Bounce>
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6}>
                  <Bounce delay={2000} triggerOnce>
                    <Paper elevation={3}>
                      <img alt="Placeholder" src="https://via.placeholder.com/100x200.png?text=Placeholder" />
                    </Paper>
                  </Bounce>
                </Grid>

              </Grid>

              <Grid container className={classes.row} spacing={2}>

                <Grid item xs={6} sm={6} md={6} lg={6}>
                  <Bounce delay={2500} triggerOnce>
                    <Paper elevation={3}>
                      <img alt="Placeholder" src="https://via.placeholder.com/100x200.png?text=Placeholder" />
                    </Paper>
                  </Bounce>
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6}>
                  <Bounce delay={3000} triggerOnce>
                    <Paper elevation={3}>
                      <img alt="Placeholder" src="https://via.placeholder.com/100x200.png?text=Placeholder" />
                    </Paper>
                  </Bounce>
                </Grid>

              </Grid>

            </Box>
          </Grid>
        </Grid>

      </Container>

    </Box>

  );
}

export default WhyUs;
